import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';

export default function ModalJustificativa(props) {
  const [enviando, setEnviando] = useState(false);
  const [justificativa, setJustificativa] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!justificativa.trim()) {
      toast.error('Por favor, informe uma justificativa', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    setEnviando(true);
    const resultado = await api.put(`/api/parceiros/${props.id}`, {
      ativo: props.novoStatus ? 1 : 0,
      justificativa: justificativa
    });

    if (resultado) {
      toast.success('Status do parceiro atualizado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
      props.onSuccess(props.id, props.novoStatus ? 1 : 0);
      props.onHide();
    }
    setEnviando(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.novoStatus ? 'Ativar' : 'Inativar'} Parceiro
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Justificativa</label>
            <textarea
              className="form-control"
              rows="3"
              value={justificativa}
              onChange={(e) => setJustificativa(e.target.value)}
              placeholder="Informe o motivo da alteração de status"
              required
            />
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={props.onHide}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={enviando}
            >
              {enviando ? 'Enviando...' : 'Confirmar'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
} 