import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

export default function ModalHistoricoJustificativas({ show, onHide, parceiro }) {
  const [justificativas, setJustificativas] = useState([]);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    console.log('Modal Props:', { show, parceiro });
    if (show && parceiro) {
      buscarJustificativas();
    } else {
      setJustificativas([]);
    }
  }, [show, parceiro]);

  const buscarJustificativas = async () => {
    setCarregando(true);
    try {
      const response = await api.get(`/api/parceiros/${parceiro.id}/justificativas`);
      if (response.data) {
        setJustificativas(response.data);
      } else {
        setJustificativas([]);
      }
    } catch (error) {
      console.error('Erro detalhado ao buscar justificativas:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      toast.error('Erro ao buscar histórico de alterações', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setJustificativas([]);
    } finally {
      setCarregando(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Histórico de Alterações - {parceiro?.nome}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {carregando ? (
          <div className="text-center p-3">
            <i className="fas fa-circle-notch fa-spin fa-2x"></i>
          </div>
        ) : justificativas.length === 0 ? (
          <p className="text-center">Nenhuma alteração de status registrada.</p>
        ) : (
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Usuário</th>
                  <th>Status Anterior</th>
                  <th>Novo Status</th>
                  <th>Justificativa</th>
                </tr>
              </thead>
              <tbody>
                {justificativas.map((j) => (
                  <tr key={j.id}>
                    <td>{moment(j.created_at).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{j.usuario?.nome || '-'}</td>
                    <td>{j.status_anterior === 1 ? 'Ativo' : 'Inativo'}</td>
                    <td>{j.status_novo === 1 ? 'Ativo' : 'Inativo'}</td>
                    <td>{j.justificativa}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
} 