import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createTransform } from 'redux-persist';

const AuthTransform = createTransform(
  (inboundState) => {
    const { autenticandoLoja, autenticandoPainel, ...persistedState } = inboundState;
    return persistedState;
  },
  (outboundState) => {
    return {
      ...outboundState,
      autenticandoLoja: false,
      autenticandoPainel: false,
    };
  },
  { whitelist: ['autenticacao'] }
);

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: 'experiencias-sw',
      storage,
      whitelist: ['autenticacao', 'usuario', 'parceiro'],
      transforms: [AuthTransform],
    },
    reducers
  );

  return persistedReducer;
};
